import React from "react"
import { Link } from 'gatsby'

import magelioLogo from "../../static/images/logo.svg"
import LinkedinLogo from "../../static/images/linkedin.svg"


import { usePageContext } from '@3nvi/gatsby-theme-intl';


export default function Header() {

  const { lang } = usePageContext();

  return (
    <header id="top" className="menu container" role="banner">
      <Link to="/">    
        <img src={magelioLogo} alt="Magel.io logo" className="menu__logo" />
      </Link>
      <nav className="menu__list">
        <ul>
          <li>
            <a href={typeof window !== 'undefined' && window.location.href.includes("privacy") ? "/" + lang + "/#jobs" : "#jobs"} className="menu__jobs menu__jobs--active smooth">
              job offers
            </a>
          </li>
          <li>
            <a href="https://sts.magel.io" className="menu__login">
              login
            </a>
          </li>
          <li>
            <a
              href="https://fr.linkedin.com/company/magel-io"
              className="menu__linkedin"
            >
              <img src={LinkedinLogo} alt="LinkedIn" />
            </a>
          </li>
          <li>
            <Link href="#contact" className="smooth">
              <button>Contact us</button>
            </Link>
          </li>
          <li>
            <Link href={typeof window !== 'undefined' && window.location.href.includes("privacy") ? "/" + lang + "/#app" : "#app"} className="smooth">
              <button>Get the app</button>
            </Link>
          </li>
        </ul>
      </nav>
    </header>
  )
}
