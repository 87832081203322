import React from "react"
import { Link } from "gatsby"

import footerArrow from "../../static/images/footer-arrow.png"
import logoFooter from "../../static/images/logo-footer.svg"

export default function Footer() {
  const handleSubmit = (event) => {
    event.preventDefault()

    var xhr = new XMLHttpRequest()

    var hubspotPortalId = "8390420"
    var hubspotFormGuid = "5ac4ea62-a0db-4a90-96ff-1e4827e4f437"
    var url =
      "https://api.hsforms.com/submissions/v3/integration/submit/" +
      hubspotPortalId +
      "/" +
      hubspotFormGuid

    var fullName = document.getElementById("name").value.split(" "),
      firstName = fullName[0],
      lastName = fullName[fullName.length - 1]

    // Example request JSON:
    var dataform = {
      fields: [
        {
          name: "email",
          value: document.getElementById("email").value,
        },
        {
          name: "firstname",
          value: firstName,
        },
        {
          name: "lastname",
          value: lastName,
        },
        {
          name: "message",
          value: document.getElementById("message").value,
        },
      ],
      context: {
        pageUri: "magel.io",
        pageName: "Magel.io landing page",
      },
      legalConsentOptions: {
        consent: {
          consentToProcess: true,
          text:
            "I agree to allow Magel.io to store and process my personal data.",
          communications: [
            {
              value: true,
              subscriptionTypeId: 999,
              text:
                "I agree to receive marketing communications from Magel.io.",
            },
          ],
        },
      },
    }

    var final_data = JSON.stringify(dataform)

    xhr.open("POST", url)
    // Sets the value of the 'Content-Type' HTTP request headers to 'application/json'
    xhr.setRequestHeader("Content-Type", "application/json")

    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4 && xhr.status === 200) {
        document.getElementById("email").value = ""
        document.getElementById("name").value = ""
        document.getElementById("message").value = ""
        document.getElementById("form-message").innerHTML =
          '<span style="color: white">Submitted!</span><br></br>'
      } else {
        document.getElementById("form-message").innerHTML =
          '<span style="color: white">You must fill all fields!</span><br></br>'
      }
    }

    // Sends the request

    xhr.send(final_data)
  }

  return (
    <footer id="contact" className="footer">
      <a href="#top" className="smooth">
        <button>
          <img src={footerArrow} alt="arrow back top" />
        </button>
      </a>
      <div className="footer__contact">
        <div className="footer__contact__blurry">&nbsp;</div>
        <header>
          <h2>Want to know what it’s all about ?</h2>
          <p></p>
        </header>
        <form className="footer__contact__form" onSubmit={handleSubmit}>
          <label htmlFor="name">Your name</label>
          <input id="name" type="text" />
          <label htmlFor="email">Your email</label>
          <input id="email" type="email" />
          <label htmlFor="message">Message</label>
          <textarea id="message" cols="25" rows="10"></textarea>
          <div id="form-message"></div>
          <button type="submit">contact us</button>
        </form>
      </div>
      <div className="footer__copyright">
        <img src={logoFooter} alt="Magel.io logo footer" />
        <p>
          ©Magel.io. 2020 All rights reserved. <br />{" "}
          <span className="color-orange">
            <Link to="/privacy-policy">Privacy policy</Link>
          </span>
        </p>
      </div>
    </footer>
  )
}
