import React from "react"

import Seo from "../components/seo"
import Header from "../sections/header"
import Footer from "../sections/footer"

import "../styles/styles.scss"

export default function Layout({children}) {
  return (
    <>
      <Seo title="Magel.io" />
      <Header />
      {children}
      <Footer />
    </>
  )
}
